const { Amplify } = require('@aws-amplify/core')
require('basscss-border/css/border.css')
require('basscss-flexbox/css/flexbox.css')
require('basscss-hide/css/hide.css')
require('basscss-layout/css/layout.css')
require('basscss-margin/css/margin.css')
require('basscss-padding/css/padding.css')
require('basscss-position/css/position.css')
require('basscss-type-scale/css/type-scale.css')
require('basscss-typography/css/typography.css')

// load amplify-js config
const amplify = require('./src/assets/conf/amplify')

exports.onClientEntry = () => {
  // initialize aws amplify
  Amplify.configure(amplify)
}
