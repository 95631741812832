// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-cant-find-js": () => import("./../../../src/pages/cant-find.js" /* webpackChunkName: "component---src-pages-cant-find-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-howitworks-js": () => import("./../../../src/pages/howitworks.js" /* webpackChunkName: "component---src-pages-howitworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leisure-js": () => import("./../../../src/pages/leisure.js" /* webpackChunkName: "component---src-pages-leisure-js" */),
  "component---src-pages-raffle-contest-js": () => import("./../../../src/pages/raffle/contest.js" /* webpackChunkName: "component---src-pages-raffle-contest-js" */),
  "component---src-pages-raffle-demo-js": () => import("./../../../src/pages/raffle/demo.js" /* webpackChunkName: "component---src-pages-raffle-demo-js" */),
  "component---src-pages-raffle-js": () => import("./../../../src/pages/raffle.js" /* webpackChunkName: "component---src-pages-raffle-js" */),
  "component---src-pages-raffle-widget-js": () => import("./../../../src/pages/raffle/widget.js" /* webpackChunkName: "component---src-pages-raffle-widget-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-doc-template-js": () => import("./../../../src/templates/docTemplate.js" /* webpackChunkName: "component---src-templates-doc-template-js" */)
}

